import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Layout from "../components/layout/layout"
import InnerWrapper from "../components/layout/innerWrapper"
import { Row, Col } from "react-bootstrap"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import SEO from "../components/seo"
const slugify = require("slugify")
//import gardenImage from "../images/portfolio/garden.jpg"

const PorfolioSubCategory = (props) => {
  const { categorySlug, category } = props.pageContext

  const data = useStaticQuery(graphql`
    {
      allPortfolioHeader {
        edges {
          node {
            categorySlug
            category
            subCategory
            seoTitle
            seoDescription
            portfolioHeader
            use
            style
            material
            content
            id
            type
            featuredImg {
              childImageSharp {
                fixed(width: 700) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  `)

  let customProjectHeader = data.allPortfolioHeader.edges.filter(
    ({ node }) => node.category == category && !node.subCategory
  )[0].node

  //console.log(customProjectHeader)

  let subcategoriesNodes = data.allPortfolioHeader.edges.filter(
    ({ node }) =>
      node.category == category &&
      node.subCategory &&
      !node.use &&
      !node.material &&
      !node.style &&
      !node.type
  )
  //let subcatgories = subcategoriesNodes.map(({ node }) => node.subCategory)

  //console.log(subcatgories)

  return (
    <Layout className="main inner-page-with-banner">
      <SEO
        title={ customProjectHeader.seoTitle || `${category} - Custom Projects` || `Walpole Outdoors - Custom Projects`}
        description={ customProjectHeader.seoDescription || `The standard all other fence and outdoor structures are measured by.`}
      />

      {/* CONTENT GOES HERE */}
      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/custom-projects">Custom Projects</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/custom-projects/${categorySlug}`}>{category}</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row className="portfolio-header">
          <h1 className="generalheader">{customProjectHeader.portfolioHeader || category}</h1>
          <div
            className="header-description"
            dangerouslySetInnerHTML={{
              __html: customProjectHeader ? (
                customProjectHeader.content
              ) : (
                <div />
              ),
            }}
          />
        </Row>

        <Row className="portfolio-grid">
          {subcategoriesNodes.map(({ node }) => {
            if (node.subCategory === "Unknown" || node.subCategory === "Other")
              return

            let slug = slugify(node.subCategory, {
              lower: true,
              remove: /[*+~.()'"!:@]/g,
              strict: true,
            })
            // let catImage = categoryImages.filter(
            //   (catImage) => catImage.name === subCat
            // )[0]

            // if (!catImage)
            //   catImage = categoryImages.filter(
            //     (catImage) => catImage.name === "Unknown"
            //   )[0]

            return (
              <Col key={node.subCategory} md={6} className="item portfolio-th">
                <Link style={{zIndex: "21"}}
                  className="_link"
                  to={`/custom-projects/${categorySlug}/${slug}`}
                ></Link>
                <Img
                  style={{
                    height: "300px",
                    width: "100%",
                    margin: 0,
                  }}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  fixed={node.featuredImg.childImageSharp.fixed}
                />
                {/* <figure
                  style={{
                    background: `linear-gradient(
                    rgba(21, 21, 21, 0.45), 
                    rgba(21, 21, 21, 0.45)
                  )`,
                  }}
                ></figure> */}
                <h2 style={{zIndex: "20"}}>{node.subCategory}</h2>
                <div className="suboverlay"></div>
              </Col>
            )
          })}
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default PorfolioSubCategory
